<template lang='pug'>
AlphabetDesktop(
    v-if="q.platform.is.desktop"
    :alphabet='alphabet'
    :activeLetter='activeLetter'
    :filteredList='filteredList'
    :currentPage="currentPage"
    :totalPages="totalPages"
    @checkActiveLetter='checkActiveLetter'
    @changePage="changePage"

)
AlphabetMobile(
    v-if="q.platform.is.mobile"
    :alphabet='alphabet'
    :activeLetter='activeLetter'
    :filteredList='filteredList'
    :currentPage="currentPage"
    :totalPages="totalPages"

    @checkActiveLetter='checkActiveLetter'
    @changePage="changePage"

)
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import AlphabetDesktop from 'components/Alphabet/AlphabetDesktop.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AlphabetMobile from 'components/Alphabet/AlphabetMobile.vue';
import { useMeta, useQuasar } from 'quasar';


export default {
    name: 'alphabet',
    components: { AlphabetMobile, AlphabetDesktop },
    async preFetch({ store }) {

    },
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        const filteredList = ref([]);

        const alphabet = ref(['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Э', 'Ю', 'Я'],
        );

        const activeLetter = ref('');
        const currentPage = ref(1);
        const totalPages = ref();

        const checkActiveLetter = (letter) => {
            letter === '0-9'
                ? activeLetter.value = '0'
                : activeLetter.value = letter;
            let productsSize = 15;
            totalPages.value = 0;
            currentPage.value = 1;
            getProducts(activeLetter.value, productsSize);
            route.params.city
                ? router.push(`/${route.params?.city}/drugs/letter-${activeLetter.value}`)
                : router.push(`/drugs/letter-${activeLetter.value}`);
        };

        const getProducts = async (query, size, page) => {
            if (query) {
                let newPage = page ? page : 1;
                let result = await store.dispatch('search/GET_PRODUCTS_FROM_LETTER', { query, size, newPage });
                if (!page) {
                    filteredList.value = result.products;
                    totalPages.value = result.totalPages;
                } else {
                    result.products
                        .forEach((item, index) => {
                            if (index !== 0) {
                                filteredList.value.push(item);
                            }
                        });
                }

            }
        };
        const changePage = (page) => {
            currentPage.value += 1;
            getProducts(activeLetter.value, 15, currentPage.value);
        };

        onMounted(() => {

            if (router.currentRoute.value.params?.letter) {
                activeLetter.value = router.currentRoute.value.params.letter;
                getProducts(activeLetter.value, 15);
            }
        });

        useMeta(() => {
            let letterRoute = activeLetter.value;
            if (router.currentRoute.value.params?.letter) {
                return {
                    title: `Cписок товаров на букву - ${letterRoute}`,
                    meta: {
                        description: { name: 'description', content: `Cписок товаров на букву ${letterRoute}` },
                    },
                    link: [
                        {
                            rel: 'canonical',
                            href: `https://${process.env.APP_SERVICE_HOSTNAME}/drugs/letter-${letterRoute}`,
                        },
                    ],
                };

            }


        });


        return {
            q,
            alphabet,
            activeLetter,
            filteredList,
            currentPage,
            checkActiveLetter,
            changePage,
            totalPages,
        };

    },
};
</script>

<style scoped lang='scss'>

</style>
